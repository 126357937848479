// Import CSS
import styles from '../css/app.pcss';

// Import Fonts
import '../fonts/NeutrafaceTextBold.woff';
import '../fonts/NeutrafaceTextBold.woff2';
import '../fonts/NeutrafaceTextBook.woff';
import '../fonts/NeutrafaceTextBook.woff2';
import '../fonts/NeutrafaceTextDemi.woff';
import '../fonts/NeutrafaceTextDemi.woff2';
import '../fonts/Perpetua-titling-mt-bold.woff';
import '../fonts/Perpetua-titling-mt-bold.woff2';
import '../fonts/Perpetua-titling-mt-regular.woff';
import '../fonts/Perpetua-titling-mt-regular.woff2';


/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import animations from './modules/globals/animations.js';
import {toggleAriaExpanded, addTabIndex, removeTabIndex } from './modules/globals/accessibility.js';
import burgerMenu from './modules/globals/hamburger_menu.js';


// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});


// ARIA Dropdowns
let dropdowns = document.getElementsByClassName('dropdown');

for (var i = dropdowns.length - 1; i >= 0; i--) {
    var menu = dropdowns[i];


    menu.addEventListener("click", (e) => {
        toggleAriaExpanded( menu );
        menu.classList.toggle('open');

        menu.querySelector('button').classList.toggle('open');
        menu.querySelector('.dropdown-list').classList.toggle('hidden');

        if(menu.classList.contains('open')){
            removeTabIndex('dropdown-child');
        }
        else{
            addTabIndex('dropdown-child');
        }
    });
};


/*	Conversion Tracking
========================================================================== */
const conversion_links = document.querySelectorAll('[data-conversion]')
if( conversion_links && typeof trackConversion != 'undefined' ) {
	conversion_links.forEach(_el => {
		_el.addEventListener('click', function() {
			const conversion_id = getConversionId(this.dataset.conversion)
			trackConversion('8fad1ae3-07f3-4f2b-9d3b-3ddc4ee171b7', conversion_id)
		})
	})
}

const getConversionId = _conversion_id => {
	if( _conversion_id.toLowerCase().indexOf('amazon') != -1 ) return 'amazon'
	else if( _conversion_id.toLowerCase().indexOf('walmart') != -1 ) return 'walmart'
	else if( _conversion_id.toLowerCase().indexOf('instacart') != -1 ) return 'instacart'

	return _conversion_id
}

/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
